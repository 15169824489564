import React from "react"
import { graphql } from "gatsby"

import Box from "@material-ui/core/Box"
import Divider from "@material-ui/core/Divider"
import Typography from "@material-ui/core/Typography"

import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

class AboutPage extends React.Component<{ data: any }> {
  render() {
    const { siteMetadata } = this.props.data.site
    return (
      <Layout>
        <SEO title="About" />
        <Box mt={4} mb={2}>
          <Typography variant="h4" gutterBottom>
            About {siteMetadata.title}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {siteMetadata.description}
          </Typography>
        </Box>
        <Divider />

        <Box mt={2}>
          <Typography variant="h5" gutterBottom>
            <Box color="success.main" fontSize="h4.fontSize" display="inline">
              M
            </Box>
            odern
          </Typography>
          <Typography variant="body1" gutterBottom>
            The UI is built with modern technology which supports desktop,
            tablet and mobile phone.
          </Typography>
        </Box>

        <Box mt={4} display="block">
          <Typography variant="h5" gutterBottom>
            <Box color="error.main" fontSize="h4.fontSize" display="inline">
              F
            </Box>
            luent
          </Typography>
          <Typography variant="body1" gutterBottom>
            Less button click and mouse moving. Easy to export data to CSV and
            save images.
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            <Box color="info.main" fontSize="h4.fontSize" display="inline">
              L
            </Box>
            ightweight
          </Typography>
          <Typography variant="body1" gutterBottom>
            All tools work in your browser without installtion, which is clean
            and safe.
          </Typography>
        </Box>

        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            <Box color="secondary.main" fontSize="h4.fontSize" display="inline">
              F
            </Box>
            ree
          </Typography>
          <Typography variant="body1" gutterBottom>
            Free to use for everyone. And forever!
          </Typography>
        </Box>
      </Layout>
    )
  }
}

export default AboutPage
